* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --lightBackground: #f8f8f8;
  /*--darkBackground: #0e1319;*/
  --darkBackground: #161619;
  --borders: #fff;
  /*--textColorDark: #0e1319;*/
  --textColorDark: #161619;
  --textColorLight: #f8f8f8;
  --textColorGray: #808080;
  --line: #2c3541;
}
.color {
  --lightBackground: #0e1319;
  --darkBackground: #f8f8f8;
  --borders: #000;
  --textColorDark: #f8f8f8;
  --textColorLight: #0e1319;
  --textColorGray: #808080;
  --line: #fff;
}
html {
  /* scroll-behavior: smooth; */
  font-size: 62.5%;
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Inter', sans-serif; */
}
h3 {
  font-size: 28px;
  font-weight: 900;
}
a, p, h1, h2, h3, h4, h5, li {
  color: var(--textColorDark);
  text-decoration: none;
  /* font-size: 1.6rem; */
}
li {
  list-style: none;
}

/* FOCUS ACTIONS
      =============================== */
body#en .main-container.active .languages button:nth-child(1),
body#es .main-container.active .languages button:nth-child(2) {
  color: var(--textColorDark);
}
body#en .main-container .languages button:nth-child(1),
body#es .main-container .languages button:nth-child(2) {
  color: var(--lightBackground);
}
body#en .main-container .languages.active button:nth-child(1),
body#es .main-container .languages.active button:nth-child(2) {
  color: var(--textColorDark);
}
body#en .main-container .languages.activeresp button:nth-child(1),
body#es .main-container .languages.activeresp button:nth-child(2) {
  color: var(--textColorDark);
}

/* SCROLLBAR
      =============================== */
body::-webkit-scrollbar {
  width: 0.6rem;
}
body::-webkit-scrollbar-track {
  background: var(--lightBackground);
}
body::-webkit-scrollbar-thumb {
  background: var(--darkBackground);
}
body::-webkit-scrollbar-thumb:active {
  background: #1c2531;
}

::selection {
  color: #fff;
  /* background: #161616; */
  /* background: #282C34; */
  background: #0B4970;
}

/* main.css
=================================== */
/* .main::after,
.main:before {
  content: "";
  position: fixed;
  z-index: -1;
  width: 50%;
  height: 100vh;
  top: 0;
  transition: 1s;
}
.main::after {
  left: 0;
  background-image: radial-gradient(
    circle at right,
    var(--borders),
    var(--lightBackground)
  );
}
.main::before {
  right: 0;
  background: var(--darkBackground);
}
.main.active::after {
  width: 77%;
}
.main.active::before {
  width: 23%;
} */
/* main .container {
  max-width: 105rem;
  margin: auto;
} */

/* LANDING
    =============================== */
/* main .sect-1 {
  position: relative;
  height: 100vh;
} */
/* main .sect-2 {
  height: 100vh;
} */
/* main .sect-3 {
  background: var(--lightBackground);
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* main .sect-1 .picture {
  position: fixed;
  z-index: 0;
  right: calc(50% - 30rem);
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: 1s;
} */
/* main .sect-1 .picture img {
  width: 60rem; 
  filter: drop-shadow(0 0 5rem rgba(0, 0, 0, 0.4));
} */
/* main.active .sect-1 .picture {
  right: calc(23% - 30rem);
} */
/* Name Container */
/* main .sect-1 .name-container {
  position: fixed;
  z-index: 2;
  bottom: 5%;
  left: 3%;
}
main .sect-1 .name-container .name {
  font-weight: 700;
  font-size: 2.8rem;
}
main .sect-1 .name-container .prof {
  font-size: 1.6rem;
  font-weight: 500;
}
main .sect-1 .name-container .name,
main .sect-1 .name-container .prof,
main .sect-1 .portf-container .name {
  text-transform: uppercase;
  opacity: 1;
  transform: scaleY(1);
  transition: 0.3s 0.5s;
} */
/* main.active .sect-1 .name-container .name,
main.active .sect-1 .name-container .prof,
main.active .sect-1 .portf-container .name {
  opacity: 0;
  transform: scaleY(0);
  transition: 0.3s;
} */
/* main .sect-1 .portf-container .name {
  color: var(--lightBackground);
}

main .sect-1 .portf-container {
  position: fixed;
  z-index: 2;
  bottom: 5%;
  right: 3%;
  cursor: pointer;
}
main .sect-1 .portf-container a {
  font-weight: 700;
  font-size: 2.8rem;
}
main .sect-1 .portf-container img {
  width: 2rem;
} */
/* SOBRE MI
    =============================== */
/* main .sect-2 .container {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 115rem;
  transform: translate(-50%, -50%);
}
main .sect-2 .container h2 {
  width: 60%;
  font-size: 2.8rem;
  font-weight: 700;
  transition: 0.5s 0s;
  transform: scaleY(0);
}
main .sect-2 .container h2:nth-child(2) {
  margin: 2rem 0 4rem;
}
main .sect-2 .container span {
  position: relative;
}
main .sect-2 .container .bt {
  transform: scaleY(0);
  transition: 0.3s;
} */
/* main.active .sect-2 .container h2,
main.active .sect-2 .container a,
main.active .sect-2 .container .bt {
  transform: scaleY(1);
  transition: transform 0.3s 0.6s;
} */
/* main .sect-2 .container .btn {
  position: relative;
  padding: 1rem 2rem;
  border: .2rem solid var(--darkBackground);
  margin: 0 0.5rem;
  display: inline-block;
  text-decoration: none;
  color: var(--darkBackground);
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  overflow: hidden;
  opacity: 1;
  transition: .5s;
}
main .sect-2 .container .btn:hover {
  color: var(--lightBackground);
}
main .sect-2 .container .btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: var(--darkBackground);
  z-index: -1;
  transition: width .5s, height .5s;
}
main .sect-2 .container .btn:hover span {
  width: 30rem;
  height: 30rem;
} */
/* BLOG
    =============================== */
/* main .blog-section {
  position: relative;
  z-index: 2;
  background: var(--darkBackground);
}
main .blog-section .container {
  padding: 10rem 0;
}
main .blog-section .container h2 {
  color: var(--textColorLight);
}
main .blog-section .container h2:first-child {
  font-size: 12rem;
  margin-bottom: 2rem;
}
main .blog-section .container h2:last-child {
  font-size: 4rem;
} */
/* PORTFOLIO
    =============================== */
/* main .sect-3 {
  position: relative;
  z-index: 2;
  min-height: 100vh;
} */
/* main .sect-3 .container {
  height: auto;
  position: relative;
  display: flex;
  padding: 10rem 0 8rem;
}
main .sect-3 .container .title {
  position: sticky;
  top: 40%;
  height: 50vh;
  width: 40%;
}
main .sect-3 .container .title h2 {
  font-size: 3.4rem;
  text-transform: uppercase;
  font-weight: 700;
}
main .sect-3 .container .title .line {
  width: 20%;
  height: 0.3rem;
  background: var(--line);
  margin: 8px 0 2rem;
}
main .sect-3 .container .title h3 {
  font-size: 2.3rem;
  font-weight: 500;
}
main .sect-3 .container .previews {
  position: relative;
  width: 70%;
  margin-left: 5rem;
} */
/* main .sect-3 .container .previews .card {
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;
  box-shadow: 2px 2px 1rem rgba(0, 0, 0, 0.1);
  transform-style: preserve-3d;
  perspective: 150rem;
  transition: 0.5s;
}
main .sect-3 .container .previews .card:hover {
  box-shadow: 1rem 1rem 8rem rgba(0, 0, 0, 0.1);
}
main .sect-3 .container .previews .card img {
  width: 100%;
  position: relative;
}
main .sect-3 .container .previews .card .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: 5rem 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  background: var(--lightBackground);
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.4s;
  overflow: hidden;
}
main .sect-3 .container .previews .card:hover .content {
  transform: scaleY(1);
}
main .sect-3 .container .previews .card .content h2 {
  color: var(--textColorDark);
  transition: 0.3s;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-size: 3rem;
  transform: translateY(-30rem);
  opacity: 0;
}
main .sect-3 .container .previews .card .content p {
  color: var(--textColorDark);
  transition: 0.3s;
  font-size: 1.6rem;
  font-weight: 500;
  transform: translateY(-30rem);
  opacity: 0;
}
main .sect-3 .container .previews .card .content .links .btn {
  position: relative;
  padding: 1rem 2rem;
  border: .2rem solid var(--darkBackground);
  margin: 0 0.5rem;
  display: inline-block;
  text-decoration: none;
  color: var(--darkBackground);
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  overflow: hidden;
  opacity: 0;
  transition: .5s;
}
main .sect-3 .container .previews .card .content .links .btn:hover {
  color: var(--lightBackground);
}
main .sect-3 .container .previews .card .content .links .btn span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: var(--darkBackground);
  z-index: -1;
  transition: width .5s, height .5s;
}
main .sect-3 .container .previews .card .content .links .btn:hover span {
  width: 30rem;
  height: 30rem;
}
main .sect-3 .container .previews .card:hover .content .links .btn {
  transition: opacity 0.3s 0.6s;
  opacity: 1;
}
main .sect-3 .container .previews .card .content .technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 4rem;
  transform: translateY(-30rem);
  transition: 0.3s;
  opacity: 0;
}
main .sect-3 .container .previews .card .content .technologies p {
  color: var(--textColorDark);
  font-size: 1.6rem;
  margin: 0 1rem 0 0;
  padding: 0;
  transform: translateY(-30rem);
}
main .sect-3 .container .previews .card .content .technologies img {
  width: 2rem;
  border-radius: 0;
  margin: 0 0.5rem;
}
main .sect-3 .container .previews .card:hover h2,
main .sect-3 .container .previews .card:hover p,
main .sect-3 .container .previews .card:hover .content .technologies,
main .sect-3 .container .previews .card .content .technologies p {
  transform: translateY(0rem);
  transition-delay: 0.3s;
  opacity: 1;
} */
/* CONTACT
    =============================== */
/* main .sect-4 {
  position: relative;
  z-index: 2;
  height: 100%;
  background: var(--darkBackground);
  background: var(--darkBackground);
}
main .sect-4 .container {
  padding: 8rem 0 12rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
}
main .sect-4 .contact-left h3 {
  color: var(--lightBackground);
  font-size: 5rem;
  font-weight: 700;
}
main .sect-4 .contact-right p {
  color: var(--lightBackground);
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.7rem;
  margin-bottom: 2rem;
}
main .sect-4 .contact-right .x {
  margin: 3rem 0 2rem;
}
main .sect-4 .contact-right a {
  color: var(--textColorLight);
  border-bottom: 1px solid var(--textColorLight);
  font-size: 2rem;
  font-weight: 500;
}
main .sect-4 .contact-footer-social {
  display: flex;
  width: fit-content;
}
main .sect-4 .icon-footer {
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
  background: var(--lightBackground);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
main .sect-4 .icon-footer a {
  border: none;
}
main .sect-4 .icon-footer img {
  margin-top: 6px;
  width: 4rem;
  padding: 1rem;
} */

/* RESPONSIVE
    =============================== */
@media only screen and (max-width: 768px) {
  /* .main::after,
  .main:before {
    width: 80%;
  }
  .main.active::after {
    width: 100%;
  }
  .main.active::before {
    width: 0%;
  } */
  /* main .sect-1 .picture {
    left: calc(80% - 23rem);
  }
  main .sect-1 .picture img {
    width: 500px;
  }
  main.active .sect-1 .picture {
    left: calc(100% - 22rem);
  } */
  /* Name Container */
  /* main .sect-1 .name-container {
    top: 15%;
    left: 10%;
    opacity: 1;
    transition: 0.3s 0.2s;
  }
  main .sect-1 .name-container .name {
    font-weight: 600;
    font-size: 22px;
  }
  main .sect-1 .name-container .prof {
    font-size: 14px;
  }
  main .sect-1 .portf-container {
    bottom: 55%;
    left: -70%;
    opacity: 1;
    transition: 0.3s 0.2s;
    transform: rotate(-90deg);
  }
  main .sect-1 .name-container.active,
  main .sect-1 .portf-container.active {
    opacity: 0;
    transition: 0.2s;
  }
  main .sect-1 .portf-container .name {
    color: var(--textColorDark);
  }
  main .sect-1 .portf-container a {
    font-size: 22px;
    color: var(--textColorDark);
  }
  main .sect-1 .portf-container img {
    background: var(--textColorDark);
    border-radius: 50%;
    padding: 5px;
    width: 1.2rem;
    margin: 0 0 -3px 0;
    transform: rotate(180deg);
  } */
  /* Landing Description */
  /* main .sect-2 .container {
    top: 30%;
    left: 10%;
    width: 70%;
  }
  main .sect-2 .container h2 {
    width: 100%;
    margin: 3rem 0;
    font-size: 1.6rem;
    font-weight: 600;
  }
  main .sect-2 .container h2 strong {
    font-weight: 600;
  } */
  /* Section 3 Portfolio */
  /* main .sect-3 {
    background: var(--lightBackground);
    height: auto;
  }
  main .sect-3 .container {
    width: 90%;
    padding: 5rem 0;
  }
  main .sect-3 .container a {
    font-weight: 600;
    color: #2c3541;
  } */

  /* Section 4 Footer Contact */
  /* main .sect-4 {
    height: 70vh;
  }
  main .sect-4 .container {
    width: 80%;
    margin: auto;
    grid-template-columns: 1fr;
  }
  main .sect-4 .contact-left h3 {
    font-size: 2.8rem;
    width: 100%;
  }
  main .sect-4 .contact-left {
    margin-bottom: 2rem;
  }
  main .sect-4 .contact-right p {
    font-size: 1.2rem;
  }
  main .sect-4 .contact-right a {
    font-size: 13px;
  } */
}

/* BORDES
    =============================== */
    /* .header {
      position: fixed;
      z-index: 4;
      top: 3rem;
      left: 0;
      right: 0;
      height: 5rem;
      display: flex;
      align-items: center;
      transition: 0.5s cubic-bezier(1, 0, 0, 1);
    }
    .main-container.active .header {
      top: 0;
    }
    .header.active,
    .header.active::before {
      height: 10rem;
    }
    .header::before {
      content: "";
      background: var(--borders);
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      height: 0;
      transition: height 0.5s cubic-bezier(1, 0, 0, 1);
    }
    .header-container {
      width: 100%;
      margin: 0 5rem;
      display: flex;
      justify-content: space-between;
      transition: 0.5s cubic-bezier(1, 0, 0, 1);
    }
    .header-container.active {
      width: 100%;
      margin: 0 10rem;
    }
    .header-container .logo {
      font-size: 1.6rem;
    }
    .header-container .logo a {
      display: flex;
      font-weight: bold;
    }
    .main-container .header-container .logo a span {
      font-weight: bold;
      transform: scaleX(0);
      transform-origin: left;
      width: 0;
      transition: 0.5s 0.5s;
      margin-right: 0.1rem;
    }
    .main-container.active .header-container .logo a span:first-child {
      margin-right: 0.7rem;
    }
    .main-container.active .header-container .logo a span {
      transform: scaleX(1);
      transform-origin: right;
      width: 100%;
    }
    
    .header-container .languages {
      display: flex;
      align-items: center;
    }
    .header-container .languages button {
      margin-left: 0.7rem;
      font-size: 1.6rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      transition: 0.5s;
      color: var(--textColorGray);
      background: transparent;
      outline: none;
      border: none;
    } */
    
    /* BOTTOM
        =============================== */
    /* .bottom {
      position: fixed;
      z-index: 4;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--borders);
      height: 0;
      display: flex;
      align-items: center;
      transition: 0.5s cubic-bezier(1, 0, 0, 1);
    }
    .bottom.active {
      height: 10rem;
    }
    .menu-footer {
      width: 100%;
      margin: 0 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transform: scaleY(0);
      transition: 0.3s;
    }
    .menu-footer.active {
      transform: scaleY(1);
    }
    .menu-footer.active.active-menu {
      transform: scaleY(0);
    }
    .menu-footer p {
      font-weight: 600;
      font-size: 1.4rem;
    }
    .menu-footer ul {
      display: flex;
      list-style: none;
    }
    .menu-footer ul li {
      margin-left: 1rem;
    }
    .menu-footer a {
      font-weight: 600;
      font-size: 1.6rem;
      position: relative;
    }
    .menu-footer a:before {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      height: 0.6rem;
      width: 100%;
      background: #cecece;
      transition: 0.3s;
      transform: scaleX(0);
      transform-origin: left;
    }
    .menu-footer a:hover:before {
      transform: scaleX(1);
    } */
    
    /* RIGHT
        =============================== */
    /* .right {
      position: fixed;
      z-index: 4;
      right: 0;
      top: 0;
      bottom: 0;
      background: var(--borders);
      width: 0;
      display: flex;
      align-items: center;
      transition: 0.5s cubic-bezier(1, 0, 0, 1);
    }
    .right.active {
      width: 10rem;
    }
    .right .social-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .right .social-container .icon {
      width: 0;
      margin: 0.5rem 0;
      transition: 0.5s;
    }
    .right.active .social-container .icon {
      width: 1.6rem;
    }
    .main-container.active .right .social-container .icon {
      width: 1.6rem;
    } */
    
    /* LEFT
        =============================== */
    /* .left {
      position: fixed;
      z-index: 4;
      left: 0;
      top: 0;
      bottom: 0;
      background: var(--borders);
      width: 0;
      display: flex;
      align-items: center;
      transition: 0.5s cubic-bezier(1, 0, 0, 1);
    }
    .left.active {
      width: 10rem;
    }
    
    .main-container.active .left,
    .main-container.active .right {
      width: 5rem;
    }
    .main-container.active .header::before,
    .main-container.active .bottom {
      height: 5rem;
    }
    
    .main-container.active .left.active,
    .main-container.active .right.active {
      width: 10rem;
    }
    .main-container.active .header.active::before,
    .main-container.active .bottom.active {
      height: 10rem;
    } */
    
    @media only screen and (max-width: 768px) {
      /* HEADER
        =============================== */
      /* .header {
        height: 0px;
      }
      .header.active,
      .header.active::before {
        height: 20px;
      }
      .header-container .logo {
        position: absolute;
        left: 40px;
        top: 30px;
        opacity: 0;
        transition: 0.8s cubic-bezier(1, 0, 0, 1);
      }
      .main-container.active .header-container .logo.active {
        opacity: 0;
      }
      .header-container .logo.active {
        opacity: 0;
        transition: 0.4s cubic-bezier(1, 0, 0, 1);
      }
      .header-container .languages {
        position: absolute;
        z-index: 20;
        left: -40px;
        top: 40px;
        opacity: 0;
        transition: 0.3s 0s;
      }
      .header-container .languages.active {
        left: 40px;
        opacity: 1;
        transition: 0.5s 0.2s;
      } */
    
      /* BOTTOM
        =============================== */
      /* .bottom {
        height: 0px;
      }
      .bottom.active {
        height: 20px;
      }
      .right {
        width: 0px;
      }
      .right.active {
        width: 20px;
      }
      .right .social-container {
        display: none;
      }
      .left {
        width: 0;
      }
      .left.active {
        width: 0;
      }
      .main-container.active .left,
      .main-container.active .right {
        width: 0;
      }
      .main-container.active .header::before,
      .main-container.active .bottom {
        height: 0;
      }
      .main-container.active .header.active::before,
      .main-container.active .bottom.active {
        height: 20px;
      } */
    }

/* Menu
========================================= */
body.active {
  overflow: hidden;
}

/* .menu {
  position: fixed;
  z-index: 3;
  left: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  transition: 0.5s cubic-bezier(1, 0, 0, 1);
}
.menu.active {
  left: 0;
} */
/* .menu-background {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  background: var(--darkBackground);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.2s 0s ease-in-out;
}
.menu-background.active {
  opacity: 1;
  transition: 0.5s 0.1s cubic-bezier(1, 0, 0, 1);
} */
/* .menu-container {
  position: absolute;
  z-index: 30;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--borders);
  height: 100%;
  transition: 0.5s cubic-bezier(1, 0, 0, 1);
}
.menu-container.active {
  right: 20%;
}

.menu-container .menu-items li a {
  position: relative;
  font-size: 6rem;
  font-weight: 900;
  line-height: 6rem;
  color: transparent;
  color: var(--textColorDark);
}
.menu-container .menu-items li a:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0.3rem;
  height: 2rem;
  width: 100%;
  background: #dddddd;
  transition: 0.3s;
  transform: scaleX(0);
  transform-origin: left;
}
.menu-container .menu-items li a:hover:before {
  transform: scaleX(1);
}
.menu-container .menu-items li {
  margin: 1rem 0;
  position: relative;
  left: 0;
  transition: 0.5s cubic-bezier(1, 0, 0, 1);
  opacity: 0;
  transform: scaleY(0);
}
.menu-container.active .menu-items li {
  left: 0;
  opacity: 1;
  transform: scaleY(1);
}
.menu-container.active .menu-items li:nth-child(1) {
  transition-delay: 0.4s;
}
.menu-container.active .menu-items li:nth-child(2) {
  transition-delay: 0.5s;
}
.menu-container.active .menu-items li:nth-child(3) {
  transition-delay: 0.6s;
}
.menu-container.active .menu-items li:nth-child(4) {
  transition-delay: 0.7s;
}
.menu-container.active .menu-items.portfolio-focus li:nth-child(2) a,
.menu-container.active .menu-items.portfolio-focus li:nth-child(2) a:before {
  -webkit-text-stroke-width: 0.1rem;
  -webkit-text-stroke-color: var(--textColorDark);
  color: var(--textColorLight);
  transform: scaleX(1);
}
.menu-container.active .menu-items.contact-focus li:nth-child(4) a,
.menu-container.active .menu-items.contact-focus li:nth-child(4) a:before {
  -webkit-text-stroke-width: 0.1rem;
  -webkit-text-stroke-color: var(--textColorDark);
  color: var(--textColorLight);
  transform: scaleX(1);
}
.menu-container .contact {
  width: 60rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.menu-container .menu-items {
  margin-top: 13rem;
}
.menu-container .contact .contact-desc {
  opacity: 0;
  transition: 0.5s;
  width: 30rem;
}
.menu-container.active .contact .contact-desc {
  opacity: 1;
}
.menu-container .contact .contact-desc p {
  font-size: 1.4rem;
  margin-bottom: 2rem;
}
.menu-container .contact .contact-desc a {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--textColorDark);
  border-bottom: 1px solid var(--textColorGray);
}
.menu-container .contact .contact-desc a:hover {
  border-bottom: 1px solid var(--textColorDark);
}
.menu-container .contact .contact-desc .mobile {
  margin-top: 2rem;
}

.menu-container .contact .contact-desc .social-container {
  display: none;
}
.menu-container .contact .contact-desc .social-container .icon {
  width: 1.5rem;
  margin-right: 1rem;
}

.menu-container::after {
  content: "";
  position: fixed;
  z-index: 40;
  left: 0;
  bottom: 20%;
  width: 5rem;
  height: 3rem;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.2s cubic-bezier(1, 0, 0, 1);
}
.menu-container.active::after {
  left: 7%;
  opacity: 1;
  transition: 0.8s cubic-bezier(1, 0, 0, 1);
} */

/* BUTTON
    =============================== */
/* .main-container .menu-button {
  user-select: none;
  position: fixed;
  top: calc(50% - 3rem);
  left: 2.2%;
  z-index: 10;
  width: 3rem;
  height: 3rem;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.main-container .menu-button p {
  position: absolute;
  font-size: 1rem;
  font-weight: 600;
  left: 2.5rem;
  padding: 1.2rem 0;
  margin-left: -0.7rem;
  transition: 0.2s all;
  transform: rotate(-90deg);
  letter-spacing: 3px;
  opacity: 1;
}
.main-container .menu-button:hover p {
  font-weight: bold;
}

.main-container .menu-button.active {
  width: 2.5rem;
  height: 2.5rem;
}
.main-container .menu-button.active p {
  opacity: 0;
}
.main-container .menu-button .button {
  width: 80%;
  height: 0.2rem;
  background: var(--textColorDark);
  position: absolute;
  top: 1.4rem;
  left: 0;
  border-radius: 5rem;
  transition: 0.2s;
}
.button:before,
.button:after {
  content: "";
  width: 60%;
  height: 0.2rem;
  background: var(--textColorDark);
  position: absolute;
  top: -0.5rem;
  right: 0;
  transition: 0.2s;
  border-radius: 5rem;
}
.button::after {
  width: 40%;
  top: 0.5rem;
}

.menu-button:hover .button:before,
.menu-button:hover .button:after {
  width: 80%;
}
.menu-button.active .button {
  background: none;
}
.menu-button.active .button:after {
  left: 0;
  top: 0;
  transform: rotate(44deg);
  height: 0.2rem;
  width: 100%;
}
.menu-button.active .button:before {
  left: 0;
  top: 0;
  transform: rotate(-44deg);
  width: 100%;
}
.menu-button.light-active .button,
.menu-button.light-active .button::before,
.menu-button.light-active .button::after {
  background: var(--textColorLight);
}
.menu-button.light-active p {
  color: var(--textColorLight);
}
.menu-button.active.light-active .button {
  background: transparent;
}
.menu-button.active.light-active .button::before,
.menu-button.active.light-active .button::after {
  background: var(--textColorDark);
}
.menu-button.active.light-active p {
  color: var(--textColorDark);
}

@media only screen and (max-width: 768px) {
  .main-container .menu-button {
    left: 83.5%;
    top: 4.3%;
    width: 3rem;
    height: 3rem;
  }
  .main-container .menu-button.active {
    width: 3rem;
    height: 3rem;
  }
  .main-container .menu-button:after {
    content: "";
    position: absolute;
    z-index: -5;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: var(--lightBackground);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  }
  .main-container .menu-button p {
    display: none;
  }
  .main-container .menu-button .button {
    height: 0.1rem;
    top: 0.9rem;
    left: 10%;
  }
  .button:before,
  .button:after {
    height: 0.14rem;
    top: -0.39rem;
    width: 100%;
  }
  .button::after {
    top: 0.35rem;
    height: 0.15rem;
  }
  .menu-button.active .button:after {
    height: 0.15rem;
  }
  .menu-button:hover .button:before,
  .menu-button:hover .button:after {
    width: 100%;
  }
  .main-container .menu-button.active .button {
    left: 10%;
    top: 45%;
  } */

  /* .menu-container .contact {
    justify-content: space-around;
    width: 50%;
    margin-left: 20%;
  }
  .menu-container .menu-items {
    margin-top: 50%;
  }
  .menu-container .menu-items li a {
    font-size: 2rem;
  }
  .menu-container .contact-desc {
    margin-top: -8rem;
  }
  .menu-container .contact p {
    font-size: 1rem;
    line-height: 1.6rem;
    margin: 0.6rem 0;
  }
  .menu-container .contact a {
    font-size: 1rem;
    font-weight: 600;
  }
  .menu-container .contact .mobile {
    margin-top: 2rem;
  }
  .menu-container .contact .social-container {
    display: flex;
  }
  .menu-container::after {
    display: none;
  } 
} */

/* SCroll 
==================== */
/* .scrollTop {
  position: fixed;
  bottom: 80px;
  right: -100px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.9) url(../images/proximo.svg);
  transform: rotate(-90deg);
  border-radius: 6px 6px 0 0;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  transition: 0.8s;
} */

/* .scrollTop.active {
  right: 0;
  visibility: visible;
  opacity: 1;
} */

/* .inner-cursor {
  position: fixed;
  z-index: 10;
  left: 1rem;
  width: 0.8rem;
  height: 0.8rem;
  transform: translate(-50%, -50%);
  background: #fff;
  mix-blend-mode: difference;
  border-radius: 50%;
  pointer-events: none;
  transition: width 0.3s, height 0.3s;
}
.inner-cursor.grow {
  width: 6rem;
  height: 6rem;
  transition: width 0.3s, height 0.3s;
}  */
/*
.outer-cursor {
  position: fixed;
  z-index: 5;
  left: 1rem;
  width: 3rem;
  height: 3rem;
  transform: translate(-50%, -50%);
  border: 0.1rem solid #fff;
  mix-blend-mode: difference;
  border-radius: 50%;
  pointer-events: none;
}*/

.loading-screen {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background-color: #4bedc2;
  width: 0%;
  height: 100%;
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}
/* @media only screen and (max-width: 768px) {
  .scrollTop {
    bottom: 130px;
    width: 40px;
    height: 40px;
    background-size: 15px;
  }
} */


/* DEMO */
/* 
.blog-demo,
.about-demo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 3rem;
  min-height: 100vh;
  width: 100%;
  color: #000;
  background: #cecece;
} */